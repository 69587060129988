import React, { useState } from 'react';

import logo from './logo.svg';
import { MotionAnimate } from 'react-motion-animate'
import './App.css';
import Logo from './asset/3d-visas.png';
import Logo2 from './asset/White background logo.png';
import Dream_To_Fly from './assets/Dream_To_Fly.png'

import Icon1 from './asset/visamentorlogo.png'
import Icon2 from './asset/workpermitlogo.png'
import Icon3 from './asset/greencardlogo.png'
import Icon4 from './asset/familyvisalogo.png'
import Icon5 from './asset/studentvisalogo.png'
import Icon6 from './asset/migratevisalogo.png'

import Facebook from './assets/Facebook.png'
import Instagram from './assets/Instagram.png'
import Whatsapp from './assets/Whatsapp.png'


import Usamap from './asset/usamap.png'
import Australiamap from './asset/australiamap.png'



import PhoneIcon from './assets/phone.png'
import MailIcon from './assets/msg.png'
import LocIcon from './assets/loc.png'


import RightSS from './assets/right-ss.png'
import StepSS from './assets/ss-step.png'
// import ApplyNow from './asset/applynowphoto.png'
import ApplyNow from './assets/planewithbackground.png'


import ContentImage1 from './asset/canadaphoto.png'
import ContentImage2 from './asset/statuoflibertyphoto.png'
import ContentImage3 from './asset/australiaphoto.png'
import ContentImage4 from './asset/canadaphoto.png'

import { NavLink } from 'react-router-dom';


function App() {
	const [menuVisibility, setMenuVisibility] = useState(false);
	const [popupVisibility, setPopupVisibility] = useState(true);
	const [applyPopupVisibility, setApplyPopupVisibility] = useState(false);
	const [readMorePopupVisibility, setReadMorePopupVisibility] = useState(false);
	let myFunction = () => {
		if (document.body.scrollTop > 350 || document.documentElement.scrollTop > 350) {
			setPopupVisibility(false)
		} else {
			setPopupVisibility(true)
		}
	}
	let myFunction2 = () => {
		setApplyPopupVisibility(!applyPopupVisibility)
	}
	window.onscroll = function () { myFunction() };
	return (
		<div className="container-fluid ">

		{applyPopupVisibility ?
			<div className="row">
				<div className="container-fluid PopupSize align-center-flex mr-0" style={{ width: '100%', position: 'absolute', height: '100vh', zIndex: '9', background: 'rgba(0,0,0,0.4)', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>

					<div className="row w-100 mt-5">
						<div className="offset-md-4 col-md-4">
							<div className="card">
								{/* <div className="card-header"> */}
								<span class="text-right p-cursor material-symbols-outlined mr-3" onClick={() => setApplyPopupVisibility(!applyPopupVisibility)}>close</span>
								{/* </div> */}
								<div className="card-body">
									<div className="çontainer-fluid">
										<div className="form-group">
											<label className="Poppins mb-2 form-label">
												I Hold a Passport From ?
											</label>
											<select className="form-select Poppins">
												<option value=""> Select Country </option>
											</select>
										</div>
										<div className="form-group mt-3 mb-3">
											<label className="Poppins mb-2 form-label">
												Service Required ?
											</label>
											<select className="form-select Poppins">
												<option value=""> Select Service Required </option>
											</select>
										</div>
										<div className="form-group mt-3">
											<label className="Poppins mb-2 form-label">
												Country ?
											</label>
											<select className="form-select Poppins">
												<option value=""> Select Country </option>
											</select>
										</div>
									</div>
									<button type="button" className="btn btn-danger btn-lg customBTN mt-4 btn-block w-100 rounded-corner"> Apply </button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			:
			''
		}
			<div className="row mainSection">
				<div className="section2">
					<div className={(popupVisibility) ? "section1" : "section1 full-width"}>
						<div className="container-fluid d-flex">
							<div className="logo">
								<img src={Logo}></img>
							</div>
							<div className="menu d-flex f-reverse space-between navbarMenuAreaHandler" onClick={() => setMenuVisibility(!menuVisibility)}>
								<span className="material-symbols-outlined">menu</span>
							</div>
							<div className={menuVisibility ? "menu d-flex space-between navbarMenuArea visible" : "menu d-flex space-between navbarMenuArea"}>
								<a href="#" className="link fw-500"> Home</a>
								<a href="#" className="link fw-500"> About Us</a>
								<a href="#" className="link fw-500"> Services</a>

								<a href="#" className="link"><button type="button" className="btn btn-danger btn-sm customBTN fs-17px rounded-corner padHorizontal-30 fw-500" onClick={() => setApplyPopupVisibility(!applyPopupVisibility)}> Apply </button></a>
								{/* <a href="#" className="link"> Who We Are</a> */}
								{/* <a href="#" className="link"> Top Destinations</a> */}
								{/* <a href="#" className="link"> Our Visa Services</a> */}
								{/* <a href="#" className="link"> Why Choose Us</a> */}
								{/* <a href="#" className="link"> Steps To Apply</a> */}
								{/* <a href="#" className="link"> We Provide The Best Visa Service</a> */}
							</div>
						</div>
					</div>
					{/* <div className="text-rotate-label text-uppercase">Trusted Visa Consultancy Firm</div> */}
					<div className="sectionOverlay">
						<div className="container-fluid d-flex space-between h-calc">
							<div className="container-fluid w-550px align-center-flex zoomHandler">
								{/* <h3 className="text-white">
									<MotionAnimate
										delay={0.2}
										speed={0.2}
										ease={[0.75, 0.45, 0.53, 0.94]}
										animation='fadeInUp' xPos={[-250, 0]} reset={false}>
										<span className="w-100 text-left">Dream to</span>
									</MotionAnimate>
									<MotionAnimate
										delay={0.4}
										speed={0.2}
										ease={[0.75, 0.45, 0.53, 0.94]}
										animation='fadeInUp' xPos={[-250, 0]} reset={false}>
										<span className="blockKeywords text-center">FLY</span>
									</MotionAnimate>
									<MotionAnimate
										delay={0.6}
										speed={0.2}
										ease={[0.75, 0.45, 0.53, 0.94]}
										animation='fadeInUp' xPos={[-250, 0]} reset={false}>
										<span className="w-100 text-center indent-170">we'll</span>
									</MotionAnimate>
									<MotionAnimate
										delay={0.8}
										speed={0.8}
										ease={[0.75, 0.45, 0.53, 0.94]}
										animation='fadeInUp' xPos={[-250, 0]} reset={false}>
										<span className="blockKeywords text-left">handle</span>
									</MotionAnimate>
									<MotionAnimate
										delay={1.0}
										speed={0.2}
										ease={[0.75, 0.45, 0.53, 0.94]}
										animation='fadeInUp' xPos={[-250, 0]} reset={false}>
										<span className="w-100 blockKeywords-light text-right">the rest.</span>
									</MotionAnimate>
								</h3> */}
								<MotionAnimate
									delay={0.2}
									speed={0.2}
									ease={[0.75, 0.45, 0.53, 0.94]}
									animation='fadeInUp' xPos={[-250, 0]} reset={false}>
									<img src={Dream_To_Fly} className='Dream_To_Fly mt-5' />
								</MotionAnimate>
								<MotionAnimate
									delay={0.4}
									speed={0.2}
									ease={[0.75, 0.45, 0.53, 0.94]}
									animation='fadeInUp' xPos={[-250, 0]} reset={false}>
									<h4 className="text-white mt-5 custom-h4">
										Discover a world of limitless possibilities with
										our comprehensive visa services. With our
										streamlined process and expert guidance, we
										make obtaining your visa effortless. Explore
										new horizons, connect cultures, and make
										unforgettable memories. Let us handle
										the paperwork while you focus on your travel.
									</h4>
								</MotionAnimate>
							</div>
							{popupVisibility ?
								<div className="container-fluid PopupSize align-center-flex mr-0">
									<div className="card form">
										<div className="container-fluid">
											{/* <div className="card-header"> */}
											<span class="text-left p-cursor material-symbols-outlined mr-0" onClick={() => setPopupVisibility(!popupVisibility)}>close</span>
											{/* </div> */}
											<div className="card-body">
												<div className="çontainer-fluid">
													<div className="form-group">
														<label className="Poppins mb-2 form-label">
															I Hold a Passport From ?
														</label>
														<select className="form-select Poppins">
															<option value=""> Select Country </option>
														</select>
													</div>
													<div className="form-group mt-3 mb-3">
														<label className="Poppins mb-2 form-label">
															Service Required ?
														</label>
														<select className="form-select Poppins">
															<option value=""> Select Service Required </option>
														</select>
													</div>
													<div className="form-group mt-3">
														<label className="Poppins mb-2 form-label">
															Country ?
														</label>
														<select className="form-select Poppins">
															<option value=""> Select Country </option>
														</select>
													</div>
												</div>
												{/* <div className="card-footer"> */}
												<button type="button" className="btn btn-danger btn-lg customBTN mt-4 btn-block w-100 rounded-corner"> Apply </button>
												{/* </div> */}
											</div>
										</div>
									</div>
								</div>
								:
								<a href="#">
									<button className='btn btn-danger applyNowBtnHero Poppins' onClick={() => setPopupVisibility(!popupVisibility)}>
										A<br />P<br />P<br />L<br />Y<br />
										{/* <br/>N<br/>O<br/>W */}
									</button>
								</a>
							}
						</div>
					</div>
				</div>
			</div>
			<div className="row mainSection2">
				<div className="container-fluid bg-logo-parallax">
					<div className="row parallax-effect">
						{/* <div className="logo2">
					<img src={Logo} alt='Logo
						' />
				</div> */}
						<div className="container pt-5 pb-5">
							<div className="row">
								<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
									<MotionAnimate
										delay={0.2}
										speed={0.2}
										ease={[0.75, 0.45, 0.53, 0.94]}
										animation='fadeInUp' xPos={[-250, 0]} reset={false}>
										<h1 className="Poppins text-center fs-3rem fg-yellow">
											Who We Are?
										</h1>
										<h3 className="text-justify mt-5 fg-yellow">We are your trusted partner in making your travel dreams a reality. With our expert team and streamlined process, we provide personalized visa services, ensuring a hassle-free journey for you. Whether it's for business, tourism, medical, or study requirements, Passport and OCI services, trust Go Visas to handle your visa requirements with efficiency and professionalism.</h3>
									</MotionAnimate>
								</div>
								{/* <div className="col-lg-4 col-md-4 col-sm-3 col-xs-12 mt-5 text-center">
								<MotionAnimate
									delay={0.4}
									speed={0.2}
									ease={[0.75, 0.45, 0.53, 0.94]}
									animation='fade' xPos={[-250, 0]} reset={false}>
									<button className="btn btn-danger btn-lg customBTN mt-5"><h1>370+</h1><h3>Completed Visa</h3></button>
								</MotionAnimate>
							</div> */}
							</div>
						</div>
					</div>
				</div>
			</div>
			{readMorePopupVisibility ?
				<div className="row">
					<div className="container-fluid PopupSize align-center-flex mr-0" style={{ width: '100%', position: 'fixed', top: 0, height: '100vh', zIndex: '9', background: 'rgba(0,0,0,0.4)', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>

						<div className="row w-100 mt-5">
							<div className="offset-md-4 col-md-4">
								<div className="card">
									{/* <div className="card-header"> */}
									<span class="text-right p-cursor material-symbols-outlined mr-3" onClick={() => setReadMorePopupVisibility(!readMorePopupVisibility)}>close</span>
									{/* </div> */}
									<div className="card-body">
										<div className="çontainer-fluid">
											<h4 className='Poppins fg-theme'>Here will be Heading.</h4>
											<p className='Poppins'>Here will be read more content.</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				:
				''
			}
			<div className="mainSection4 mt-5 mb-5 text-center">
				<div className="container-fluid">
					<MotionAnimate
						delay={0.2}
						speed={0.2}
						ease={[0.75, 0.45, 0.53, 0.94]}
						animation='fadeInUp' xPos={[-250, 0]} reset={false}>
						<h1 className="Poppins h1-custom fw-bold fg-theme">Our Visa Services</h1>
					</MotionAnimate>
					<MotionAnimate
						delay={0.4}
						speed={0.2}
						ease={[0.75, 0.45, 0.53, 0.94]}
						animation='fadeInUp' xPos={[-250, 0]} reset={false}>
						<h3 className="Poppins h4 fg-theme">Not Just Any Traditional Visa Service, <br />But A Fully Customized Experience.</h3>
					</MotionAnimate>
				</div>
				<div className="mainSection4Container container-fluid mt-5">
					<div className="row">
						<div className="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-xs-6 mt-3" onClick={() => setReadMorePopupVisibility(!readMorePopupVisibility)}>
							<MotionAnimate
								delay={0.4}
								speed={0.2}
								ease={[0.75, 0.45, 0.53, 0.94]}
								animation='fadeInUp' xPos={[-250, 0]} reset={false}>
								<div className="container-fluid zoomEffect p-cursor gradient-bg-theme h-200 text-left rounded-corner ">
									<br />
									<div className="rounded-corner icon">
										<img src={Icon1} />
									</div>
									<h3 className="Poppins fg-white mt-3">Tourist Visa</h3>
									<p className="Poppins fg-white">Read More</p>
								</div>
							</MotionAnimate>
						</div>
						<div className="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-xs-6 mt-3">
							<MotionAnimate
								delay={0.6}
								speed={0.2}
								ease={[0.75, 0.45, 0.53, 0.94]}
								animation='fadeInUp' xPos={[-250, 0]} reset={false}>
								<div className="container-fluid zoomEffect p-cursor gradient-bg-theme h-200 text-left rounded-corner" onClick={() => setReadMorePopupVisibility(!readMorePopupVisibility)}>
									<br />
									<div className="rounded-corner icon">
										<img src={Icon2} />
									</div>
									<h3 className="Poppins fg-white mt-3">Medical Visa</h3>
									<p className="Poppins fg-white">Read More</p>
								</div>
							</MotionAnimate>
						</div>
						<div className="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-xs-6 mt-3">
							<MotionAnimate
								delay={0.8}
								speed={0.2}
								ease={[0.75, 0.45, 0.53, 0.94]}
								animation='fadeInUp' xPos={[-250, 0]} reset={false}>
								<div className="container-fluid zoomEffect p-cursor gradient-bg-theme h-200 text-left rounded-corner" onClick={() => setReadMorePopupVisibility(!readMorePopupVisibility)}>
									<br />
									<div className="rounded-corner icon">
										<img src={Icon3} />
									</div>
									<h3 className="Poppins fg-white mt-3">Business Visa</h3>
									<p className="Poppins fg-white">Read More</p>
								</div>
							</MotionAnimate>
						</div>
						<div className="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-xs-6 mt-3">
							<MotionAnimate
								delay={1.0}
								speed={0.2}
								ease={[0.75, 0.45, 0.53, 0.94]}
								animation='fadeInUp' xPos={[-250, 0]} reset={false}>
								<div className="container-fluid zoomEffect p-cursor gradient-bg-theme h-200 text-left rounded-corner" onClick={() => setReadMorePopupVisibility(!readMorePopupVisibility)}>
									<br />
									<div className="rounded-corner icon">
										<img src={Icon4} />
									</div>
									<h3 className="Poppins fg-white mt-3">Family Visit Visa</h3>
									<p className="Poppins fg-white">Read More</p>
								</div>
							</MotionAnimate>
						</div>
						<div className="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-xs-6 mt-3">
							<MotionAnimate
								delay={1.2}
								speed={0.2}
								ease={[0.75, 0.45, 0.53, 0.94]}
								animation='fadeInUp' xPos={[-250, 0]} reset={false}>
								<div className="container-fluid zoomEffect p-cursor gradient-bg-theme h-200 text-left rounded-corner" onClick={() => setReadMorePopupVisibility(!readMorePopupVisibility)}>
									<br />
									<div className="rounded-corner icon">
										<img src={Icon5} />
									</div>
									<h3 className="Poppins fg-white mt-3">Student Visa</h3>
									<p className="Poppins fg-white">Read More</p>
								</div>
							</MotionAnimate>
						</div>
						<div className="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-xs-6 mt-3">
							<MotionAnimate
								delay={1.4}
								speed={0.2}
								ease={[0.75, 0.45, 0.53, 0.94]}
								animation='fadeInUp' xPos={[-250, 0]} reset={false}>
								<div className="container-fluid zoomEffect p-cursor gradient-bg-theme h-200 text-left rounded-corner" onClick={() => setReadMorePopupVisibility(!readMorePopupVisibility)}>
									<br />
									<div className="rounded-corner icon">
										<img src={Icon6} />
									</div>
									<h3 className="Poppins fg-white mt-3">Passport & OCI Service</h3>
									<p className="Poppins fg-white">Read More</p>
								</div>
							</MotionAnimate>
						</div>
					</div>
				</div>
			</div>
			<div className="mainSection6 mt-5 mb-5 text-left">
				<div className="container-fluid">
					<br />
					<MotionAnimate
						delay={0.0}
						speed={0.2}
						ease={[0.75, 0.45, 0.53, 0.94]}
						animation='fadeInUp' xPos={[-250, 0]} reset={false}>
						<h1 className="Poppins fg-theme text-center">Get your Visa in 3 Easy and Simple Steps</h1>
					</MotionAnimate>
					<br />
					{/* <h3 className="Poppins fg-theme">Not Just Any Traditional Visa &<br />Immigration Firm</h3> */}
				</div>
				<div className='row'>
					{/* <div className='col-12 text-center'>
						<img src={StepSS} className='w-100' />
					</div> */}
					<div className='col-12 text-center TestContainer'>
						<div className='row backgroundStep flex-end'>
							<div className='col-lg-5 col-md-12 col-sm-12 col-xs-12 applyNow-container'>
								<MotionAnimate
									delay={0.0}
									speed={0.2}
									ease={[0.75, 0.45, 0.53, 0.94]}
									animation='fade' xPos={[-250, 0]} reset={false}>
									<img src={ApplyNow} className='w-100 applyNow-Image' />
								</MotionAnimate>
							</div>
							<div className='col-lg-7 col-md-12 col-sm-12 col-xs-12 applyNow-container'>
								<div className='container-fluid'>
									<div className='row'>
										<div className='col-lg-4 col-md-4 col-sm-4 col-xs-12'>
											<MotionAnimate
												delay={0.0}
												speed={0.2}
												ease={[0.75, 0.45, 0.53, 0.94]}
												animation='fadeInUp' xPos={[-250, 0]} reset={false}>
												<div className='row'>
													<div className='container-fluid step-container'>
														<div className='Circle'><h1 className='Poppins fg-white'>1</h1></div>
														<div className='textContent'>
															<h1 className='Poppins fg-white fs25px'>We will <br />guide and <br />review your<br />documents</h1>
														</div>
														<div className='button rounded-corner bg-red fg-white' onClick={() => setReadMorePopupVisibility(!readMorePopupVisibility)}><h1 className='Poppins'>Learn More</h1></div>
													</div>
												</div>
											</MotionAnimate>
										</div>
										<div className='col-lg-4 col-md-4 col-sm-4 col-xs-12'>
											<MotionAnimate
												delay={0.2}
												speed={0.2}
												ease={[0.75, 0.45, 0.53, 0.94]}
												animation='fadeInUp' xPos={[-250, 0]} reset={false}>
												<div className='row'>
													<div className='container-fluid step-container'>
														<div className='Circle'><h1 className='Poppins fg-white'>2</h1></div>
														<div className='textContent'>
															<h1 className='Poppins fg-white fs25px'>We will prepare<br />and submit <br />your online<br />application</h1>
														</div>
														<div className='button rounded-corner bg-red fg-white' onClick={() => setReadMorePopupVisibility(!readMorePopupVisibility)}><h1 className='Poppins'>Learn More</h1></div>
													</div>
												</div>
											</MotionAnimate>
										</div>
										<div className='col-lg-4 col-md-4 col-sm-4 col-xs-12'>
											<MotionAnimate
												delay={0.4}
												speed={0.2}
												ease={[0.75, 0.45, 0.53, 0.94]}
												animation='fadeInUp' xPos={[-250, 0]} reset={false}>
												<div className='row'>
													<div className='container-fluid step-container'>
														<div className='Circle'><h1 className='Poppins fg-white'>3</h1></div>
														<div className='textContentExtra'>
															<h1 className='Poppins fg-white fs25px'>You receive<br />your visa</h1>
														</div>
														<div className='button rounded-corner bg-red fg-white' onClick={() => setReadMorePopupVisibility(!readMorePopupVisibility)}><h1 className='Poppins'>Learn More</h1></div>
													</div>
												</div>
											</MotionAnimate>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="mainSection5 mt-5 mb-5 text-center">
				<div className='row bg-black-transparent'>
					<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center-mobile'>
						<MotionAnimate
							delay={0.2}
							speed={0.2}
							ease={[0.75, 0.45, 0.53, 0.94]}
							animation='fadeInUp' xPos={[-250, 0]} reset={false}>
							<h1 className='fg-yellow'>Enjoy the best in class visa services for all of your visa or passport & OCI needs.</h1>
						</MotionAnimate>
					</div>
					<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-5'>
						<MotionAnimate
							delay={0.0}
							speed={0.2}
							ease={[0.75, 0.45, 0.53, 0.94]}
							animation='fadeInUp' xPos={[-250, 0]} reset={false}>
							<div className='row'>
								<div className='col-lg-4 col-md-4 col-sm-4 col-xs-4'>
									<h1 className='fg-ash'><span className='gradient-counters'>1600+</span></h1>
									<h3 className='fg-yellow'>Customers</h3>
								</div>
								<div className='col-lg-4 col-md-4 col-sm-4 col-xs-4'>
									<h1 className='fg-ash'><span className='gradient-counters'>26+</span></h1>
									<h3 className='fg-yellow'>Destinations</h3>
								</div>
								<div className='col-lg-4 col-md-4 col-sm-4 col-xs-4'>
									<h1 className='fg-ash'><span className='gradient-counters'>14+</span></h1>
									<h3 className='fg-yellow'>Service Types</h3>
								</div>
							</div>
						</MotionAnimate>
					</div>
					{/* <div className='col-lg-2 col-md-12 col-sm-12 col-xs-12 text-center'>
						<MotionAnimate
							delay={0.4}
							speed={0.2}
							ease={[0.75, 0.45, 0.53, 0.94]}
							animation='fadeInUp' xPos={[-250, 0]} reset={false}>
							<button className="btn btn-danger btn-lg customBTN btn-rounded"><h3>Explore More</h3></button>
						</MotionAnimate>
					</div> */}
				</div>
			</div>
			<div className="mainSection6 mt-5 mb-5 text-left">
				<div className='row'>
					<div className='col-lg-5 col-md-12 col-sm-12 col-xs-12 pl-3 pr-3'>
						<div className='p-3'>
							<MotionAnimate
								delay={0.6}
								speed={0.2}
								ease={[0.75, 0.45, 0.53, 0.94]}
								animation='fadeInUp' xPos={[-250, 0]} reset={false}>
								<h1 className='Poppins fg-themeDark fs-3rem'>Why Choose Us ?</h1>
							</MotionAnimate>
							{/* <MotionAnimate
								delay={0.8}
								speed={0.2}
								ease={[0.75, 0.45, 0.53, 0.94]}
								animation='fadeInUp' xPos={[-250, 0]} reset={false}>
								<h3 className='Poppins fg-theme'>Not Just Any Traditional Visa &amp; Immigration Firm?</h3>
							</MotionAnimate> */}
							<MotionAnimate
								delay={1.0}
								speed={0.2}
								ease={[0.75, 0.45, 0.53, 0.94]}
								animation='fadeInUp' xPos={[-250, 0]} reset={false}>
								<h3 className="Poppins fg-theme2 text-justify para mt-5 mb-5">Choose us for a visa experience that transcends the ordinary. Our devoted team meticulously streamlines the intricate visa process, guaranteeing precision and promptness at every step. Fueled by a steadfast commitment to excellence, we effortlessly navigate through complexities that may arise. Place your trust in us for personalized service, open and transparent communication, and a journey free from stress and hassles. Your visa success stands as our paramount priority, establishing us as your perfect companion for a seamless exploration of the global landscape. Embrace the assurance that our dedicated efforts and unwavering focus on your visa needs make us the ultimate choice for those seeking a reliable partner in their international endeavors.</h3>
							</MotionAnimate>
							<MotionAnimate
								delay={1.2}
								speed={0.2}
								ease={[0.75, 0.45, 0.53, 0.94]}
								animation='fadeInUp' xPos={[-250, 0]} reset={false}>
								<div className='d-flex'>
									<span class="material-symbols-outlined">done</span>
									<h1 className='Poppins fg-theme keyPoints d-inline mt-3'> &nbsp; Trusted By Clients</h1>
								</div>
							</MotionAnimate>
							<MotionAnimate
								delay={1.4}
								speed={0.2}
								ease={[0.75, 0.45, 0.53, 0.94]}
								animation='fadeInUp' xPos={[-250, 0]} reset={false}>
								<div className='d-flex'>
									<span class="material-symbols-outlined">done</span>
									<h1 className='Poppins fg-theme keyPoints d-inline mt-3'> &nbsp; Fast & Secure</h1>
								</div>
							</MotionAnimate>
							<MotionAnimate
								delay={1.6}
								speed={0.2}
								ease={[0.75, 0.45, 0.53, 0.94]}
								animation='fadeInUp' xPos={[-250, 0]} reset={false}>
								<div className='d-flex'>
									<span class="material-symbols-outlined">done</span>
									<h1 className='Poppins fg-theme keyPoints d-inline mt-3'> &nbsp; World Class Service</h1>
								</div>
							</MotionAnimate>
							<MotionAnimate
								delay={1.8}
								speed={0.2}
								ease={[0.75, 0.45, 0.53, 0.94]}
								animation='fadeInUp' xPos={[-250, 0]} reset={false}>
								<div className='d-flex'>
									<span class="material-symbols-outlined">done</span>
									<h1 className='Poppins fg-theme keyPoints d-inline mt-3'> &nbsp; Best Price Guarantee</h1>
								</div>
							</MotionAnimate>
							<MotionAnimate
								delay={1.8}
								speed={0.2}
								ease={[0.75, 0.45, 0.53, 0.94]}
								animation='fadeInUp' xPos={[-250, 0]} reset={false}>
								<div className='d-flex'>
									<span class="material-symbols-outlined">done</span>
									<h1 className='Poppins fg-theme keyPoints d-inline mt-3'> &nbsp; Beyond The Clock Dedication </h1>
								</div>
							</MotionAnimate>
						</div>
					</div>
					<div className='col-lg-7 col-md-12 col-sm-12 col-xs-12 text-center m-auto'>
						<MotionAnimate
							delay={1.0}
							speed={0.2}
							ease={[0.75, 0.45, 0.53, 0.94]}
							animation='fade' xPos={[-250, 0]} reset={false}>
							<img src={RightSS} className='w-35' />
						</MotionAnimate>
					</div>
				</div>
			</div>
			<div className="row mainSection3 mb-5 mt-5">
				<div className="container-fluid global">
					<div className="row globeSubSection">
						<div className="container">
							<div className="row">
								<div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
									<MotionAnimate
										delay={0.0}
										speed={0.2}
										ease={[0.75, 0.45, 0.53, 0.94]}
										animation='fade' xPos={[-250, 0]} reset={false}>
										<h1 className="heading Poppins fg-white fs-50px">Our Top Destinations</h1>
										<h4 className="subHeading Poppins fg-white">Explore a diverse array of destination packages curated just for you.</h4>
									</MotionAnimate>
								</div>
								<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 text-center">
									<MotionAnimate
										delay={0.2}
										speed={0.2}
										ease={[0.75, 0.45, 0.53, 0.94]}
										animation='fade' xPos={[-250, 0]} reset={false}>
										<button className="btn btn-danger btn-lg customBTN mt-2"><h2>Choose your Country</h2></button>
									</MotionAnimate>
								</div>
							</div>
						</div>
					</div>
					{/* <MotionAnimate
						delay={0.5}
						speed={0.5}
						ease={[0.75, 0.45, 0.53, 0.94]}
						animation='fadeInUp' xPos={[-250, 0]} reset={false} className="h-inherit"> */}
					<div className="row globeSubSection">
						<div className="floating-cards">
							<div className="content h-inherit">
								<div className="rounded-corners">
									<img className="w-140" src={ContentImage1} />
								</div>
								<div className="p-2 pl-5">
									<div className='d-flex d-space-between'>
										<h1 className="Poppins fg-white">Dubai</h1>
										<img className='w-50px' src={Usamap} />
									</div>
								</div>
							</div>
						</div>
						{/* </MotionAnimate>
							<MotionAnimate
							delay={1.5}
							speed={0.5}
							ease={[0.75, 0.45, 0.53, 0.94]}
							animation='fadeInUp' xPos={[-250, 0]} reset={false}> */}
						<div className="floating-cards">
							<div className="content">
								<div className="rounded-corners">
									<img className="w-140" src={ContentImage2} />
								</div>
								<div className="p-2 pl-5">
									<div className='d-flex d-space-between'>
										<h1 className="Poppins fg-white">United States</h1>
										<img className='w-50px' src={Usamap} />


									</div>
								</div>
							</div>
						</div>
						{/* </MotionAnimate>
							<MotionAnimate
							delay={1.5}
							speed={0.5}
							ease={[0.75, 0.45, 0.53, 0.94]}
							animation='fadeInUp' xPos={[-250, 0]} reset={false}> */}
						<div className="floating-cards">
							<div className="content">
								<div className="rounded-corners">
									<img className="w-140" src={ContentImage1} />
								</div>
								<div className="p-2 pl-5">
									<div className='d-flex d-space-between'>
										<h1 className="Poppins fg-white">Canada</h1>
										<img className='w-50px' src={Australiamap} />
									</div>
								</div>
							</div>
						</div>
						{/* </MotionAnimate>
							<MotionAnimate
							delay={1.5}
							speed={0.5}
							ease={[0.75, 0.45, 0.53, 0.94]}
							animation='fadeInUp' xPos={[-250, 0]} reset={false}> */}
						<div className="floating-cards">
							<div className="content">
								<div className="rounded-corners">
									<img className="w-140" src={ContentImage3} />
								</div>
								<div className="p-2 pl-5">
									<div className='d-flex d-space-between'>
										<h1 className="Poppins fg-white">Australia</h1>
										<img className='w-50px' src={Australiamap} />


									</div>
								</div>
							</div>
						</div>
					</div>
					{/* </MotionAnimate> */}
				</div>
			</div>
			<hr />
			<div className="row backgroundTempFooterSection">
				<div className="mainSection6 bg-footer-transparency text-center">
					<div className="container-fluid p-5">
						<div className='row'>
							<div className='col-lg-4 col-md-4 col-sm-12 col-xs-12'>

								<div className="logo3 text-left">
									<img src={Logo}></img>
								</div>
								<h4 className='fg-theme Poppins text-justify footer-desc'>
									Welcome to GO VISAS! We are your trusted partner in making your travel dreams a reality. With our expert team and streamlined process, we provide personalized visa services, ensuring a hassle-free journey for you. Whether it's for business, tourism, medical or study requirements, trust GO VISAS to handle your visa requirements with efficiency and professionalism. Your adventure awaits you!
								</h4>
							</div>
							<div className='col-lg-8 col-md-8 col-sm-12 col-xs-12'>
								<div className='container-fluid text-left'>
									<div className='row'>
										<div className='col-lg-5 col-md-5 col-sm-4 col-xs-12'>
											<h1 className='Poppins fg-theme mt-4 mb-4 footer-heading'>Contact Us At: -</h1>
											<h5 className='Poppins fg-theme mt-4 mb-4'>
												<div className='d-inline'>
													<img className={'footerIcon'} src={Whatsapp} />
													<span className='d-inline link-footer'>+447875743834</span>
												</div>
											</h5>
											<h5 className='Poppins fg-theme mt-4 mb-4'>
												<div className='d-inline'>
													<img className={'footerIcon'} src={MailIcon} />
													<span className='d-inline link-footer'>office@govisas.co.uk</span>
												</div>
											</h5>
											<h5 className='Poppins fg-theme mt-4 mb-4'>
												<div className='d-inline'>
													<img className={'footerIcon'} src={LocIcon} />
													<span className='d-inline link-footer'>London, SE18 5JH, United Kingdom</span>
												</div>
											</h5>
											<div className="logo3 text-left">
												<h5 className='Poppins fg-theme mt-4 mb-4'>
													Follow Us on :
												</h5>
												<img className='w-40px m-0 mr-3' src={Facebook}></img>
												<img className='w-40px m-0 mr-3' src={Instagram}></img>
											</div>
										</div>
										<div className='col-lg-3 col-md-3 col-sm-4 col-xs-12'>
											<h1 className='Poppins fg-theme mt-4 mb-4 footer-heading'>Explore: -</h1>
											<a href='#' className='link-footer'>
												Home
											</a>
											<a href='#' className='link-footer'>
												About Us
											</a>
											<a href='#' className='link-footer'>
												Services
											</a>
											<a href='#' className='link-footer'>
												Apply Now!
											</a>
										</div>
										<div className='col-lg-4 col-md-4 col-sm-4 col-xs-12'>
											<h1 className='Poppins fg-theme mt-4 mb-4 footer-heading'>Info: -</h1>
											<a href='#' className='link-footer'>
												Cancellation Policy
											</a>
											<a href='#' className='link-footer'>
												Data Policy
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="p-2 bg-theme text-center" style={{ borderTop: '1px solid #fff' }}>
					<div className="container-fluid"><h4 className='Poppins copyright-desc text-light mt-2 fw-bold'>CopyRight &copy; 2023 | Go VISAS | All Rights Reserved.</h4></div>
				</div>
				</div>
		</div>
	);
}

export default App;
